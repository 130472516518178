.deleted__records {
  color: #c3b9b9 !important;
  text-decoration: line-through #cb2c2c;
}

.disabled__btn {
  border-color: '#9c9c9d';
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inputDiv {
  margin: 50px;
}

.rightBtnGrid {
  display: flex;
  align-items: center;
}

.rightBtnGrid .rightBtn {
  height: 40px;
}

.btnContainer {
  display: flex;
  justify-content: right;
  padding-top: 12px;
  /* padding-bottom: 10px; */
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0px;


}

a {
  text-decoration: none;
}

.MuiGrid-container>.MuiDivider-vertical.MuiDivider-flexItem {
  margin-right: -1px;
}

.formControl {
  width: 100%;
}

.formControl .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-top: 1px;
  padding-bottom: 1px;
  height: 40px;
}

.formControl .MuiInputLabel-formControl {
  top: -8px;
}

.formControl .MuiInputBase-root {
  height: 40px;
}

.formControl .MuiOutlinedInput-multiline {
  min-height: 80px;
}

.formControl .MuiOutlinedInput-input {
  color: #535456;
}

/* .formControl .MuiOutlinedInput-input{padding:8.5px 14px 10.5px 14px; font-size:16px; color:#535456;} */
.formControl .MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: -2px;
}

.formControl .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  color: #535456;
}

.formControl {
  margin: 5px 0px
}

.customheading {
  margin: 0 0 15px -25px;
  font-size: 14px;
  color: #fff;
  background-color: #303e67;
  display: inline-block;
  line-height: 1.3;
  padding: 4px 8px 4px 8px;
  min-height: 26px;
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.customheading:after {
  position: absolute;
  content: "";
  right: -13px;
  top: 0;
  height: 0;
  width: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 13px solid #303E67;
}
/* .customheading {
  margin: 0 0 15px -25px;
  font-size: 14px;
  color: #fff;
  background-color: #303e67;
  display: inline-block;
  line-height: 1.3;
  padding: 3px 8px 3px 10px;
  margin-bottom: 3px !important;
  min-height: 26px;
  position: relative;
  font-family: 'Roboto', sans-serif;
} */
.customheading  span{
display: block;
max-width: 200px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
.customheading:after {
  position: absolute;
  content: "";
  right: -13px;
  top: 0;
  height: 0;
  width: 0;
  border-top: 15px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 14px solid #303E67;
}
@media screen and (max-width: 601px) {
  .customheading {
    font-size: 12px;
    min-height: 20px;
    
  }
  .customheading:after {
    
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 13px solid #303E67;
   
  }
}
@media screen and (min-width: 1281px) {
  
  .customheading:after {
    
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 14px solid #303E67;
   
  }
}
.areaHeading {
  margin: 0;
  color: #fff;
  background-color: #303e67;
  display: inline-block;
  padding: 4px 8px 4px 8px;
  min-height: 26px;
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.areaHeading:after {
  position: absolute;
  content: "";
  right: -13px;
  top: 0;
  height: 0;
  width: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 13px solid #303E67;
}

.tag {
  background-color: #f4f8ff;
  border-radius: 4px;
  margin: 4px 0 0;
  /* font-size: 14px; */
  color: #303e67;
  padding: 4px;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.tag .MuiSvgIcon-root{
  font-size: inherit;
  width: 12px;
  height: 12px;
  font-weight: 500;
}
/* .para {
  font-weight: 400;

  text-align: justify;
  padding-left: 10px;
  margin-top: 10px !important;
  line-height:130%;
} */
.btn_more{
  padding: 0px;
  border: 0;
  background: transparent;
  color: #1761fd;
  font-weight: 600;
}

.overdue{
  color: #f30505 !important;
  padding: 5px;
  border-radius: 10px;
}
.nearTodue{
  color: #ec7b44 !important;
  padding: 5px;
  border-radius: 10px;
}
.upcoming {
  /* background-color: #edf2f6 !important; */
  color: #175888 !important;
  padding: 5px;
  border-radius: 10px;
}
.completed {
  /* background-color: #f1f8ec !important; */
  color: #5caa2d !important;
  padding: 5px;
  border-radius: 10px;
}
.inProgress {
  /* background-color: #fff9ee !important; */
  color: #ec7b44 !important;
  padding: 5px;
  border-radius: 10px;
}
.awaitingApproval {
  /* background-color: #fff0f7 !important; */
  color: #cd3552 !important;
  padding: 5px;
  border-radius: 10px;
}
.readyForReview {
  /* background-color: #f4f5fe !important; */
  color: #3342ce !important;
  padding: 5px;
  border-radius: 10px;
}
.pl{
padding-left: 7px;
}
.pt{
  padding-top: 5px;
}
pre {
  display: block;
  font-family: inherit;
  white-space-collapse: preserve;
  text-wrap: inherit;
  margin: inherit;
}
pre:last-child{
  display: flex;
}